<template>
  <div class="wrapper-content">
    <div
      class="sportsOne"
      @click="sportsClick(item)"
      v-for="(item, index) in dataList"
      :key="index"
    >
      <div class="sportsLe">
        <div class="sportsTitle">{{ item.journalisTitle }}</div>
        <div class="sportsDate">{{ formatDateTime(item.createTime) }}</div>
        <div class="sportsTop">
          <img :src="loadUrl(item.coverImg)" alt="" />
        </div>
        <div class="ql-editor" v-html="item.contentText"></div>
      </div>
      <div class="sportsRi">
        <img :src="loadUrl(item.coverImg)" alt="" />
      </div>
    </div>
    <el-pagination
      id="pcPagination"
      v-if="total > 0"
      class="page-bar"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="params.currentPage"
      :page-sizes="[10, 20, 50, 100]"
      :page-size="params.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
    <el-pagination
      id="phonePagination"
      class="page-bar"
      background
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="params.currentPage"
      layout="prev, pager, next"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
<script>
import { getSportsConsulting } from "@/api/partners";
import { getTextarea } from "@/api/template";
export default {
  data() {
    return {
      params: {
        currentPage: 1,
        pageSize: 10,
      },
      dataList: [],
      total: 0,
    };
  },
  created() {
    this.initData();
  },
  methods: {
    sportsClick(item) {
      this.$router.push({ path: "/sportsInfo-details", query: { item: item } });
    },
    initData() {
      getSportsConsulting(this.params).then((res) => {
        let data = res.data;
        data.list.forEach((el) => {
          let params = {
            journalisContent: el.journalisContent,
          };
          getTextarea(params).then((rese) => {
            el.journalisContent = rese.data.dataHtml;
            var msg = rese.data.dataHtml
              .replace(/<(p|div)[^>]*>(<br\/?>|&nbsp;)<\/\1>/gi, "\n")
              .replace(/<br\/?>/gi, "\n")
              .replace(/<[^>/]+>/g, "")
              .replace(/(\n)?<\/([^>]+)>/g, "")
              .replace(/\u00a0/g, " ")
              .replace(/&nbsp;/g, " ")
              .replace(/<\/?(img)[^>]*>/gi, "")
              .replace(/&amp;/g, "&")
              .replace(/&lt;/g, "<")
              .replace(/&gt;/g, ">")
              .replace(/&#39;/g, "'")
              .replace(/&quot;/g, '"')
              .replace(/<\/?.+?>/g, "");
            this.$set(el, "contentText", msg);
          });
        });
        this.dataList = data.list;
        this.total = data.pagination.total;
      });
    },
    handleSizeChange(val) {
      this.params.pageSize = val;
      this.initData();
    },
    handleCurrentChange(val) {
      this.params.currentPage = val;
      this.initData();
    },
  },
};
</script>
<style lang="scss" scoped>
.wrapper-content {
  padding-top: 30px;
  min-height: 100%;
  .sportsOne {
    width: 100%;
    background-color: #ffffff;
    margin: 12px 0;
    display: flex;
    box-shadow: 0px 0px 20px 0px rgba(154, 154, 154, 0.14);
    padding: 30px;
    box-sizing: border-box;
    cursor: pointer;
    &:hover {
      box-shadow: 0px 0px 16px 0px rgba(76, 76, 76, 0.33);
      transition: all 0.5s;
    }
    .sportsLe {
      flex: 1;
      .sportsTitle {
        font-size: 22px;
        &:hover {
          color: #1e50ae;
        }
      }
      .sportsDate {
        color: #666666;
        margin: 10px 0 16px;
        font-size: 14px;
      }
      .ql-editor {
        color: #666666;
        line-height: 28px;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
        font-size: 16px;
        max-height: 100px;
      }
    }
    .sportsRi {
      width: 340px;
      max-height: 200px;
      text-align: right;
      img {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }
  .page-bar {
    margin-top: 30px;
  }
}
@media only screen and (min-width: 768px) {
  .sportsTop {
    display: none;
  }
  #phonePagination {
    display: none;
  }
}
@media only screen and (max-width: 768px) {
  #pcPagination {
    display: none;
  }
  .page-bar {
    justify-content: center;
    margin-bottom: 30px;
  }
  .wrapper-content {
    padding: 10px 0;
    .sportsOne {
      display: block;
      padding: 10px;
      margin: 6px 0;
      .sportsLe {
        .sportsTitle {
          font-size: 18px;
        }
        .sportsDate {
          margin: 6px 0;
          font-size: 12px;
        }
        .ql-editor {
          margin-top: 4px;
          font-size: 14px;
        }
      }
      .sportsTop {
        width: 100%;
      }
      .sportsRi {
        display: none;
      }
    }
  }
}
</style>
